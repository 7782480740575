<template>
  <v-dialog v-if="noticiaSeleccionada" persistent scrollable fullscreen :scrim="false"
    transition="dialog-bottom-transition">
    <v-card style="border-radius: 15px" width="500px">
      <v-sheet>
        <v-card-item>
          <template v-slot:prepend>
            <div class="pl-3">
            
              <v-chip>
                <span class="text-caption">
                  Publicada el: {{
                    dateFormat(
                      noticiaSeleccionada?.publicationDate.seconds,
                      true
                    )
                  }}</span>
              </v-chip>
            </div>
          </template>
          <template v-slot:append>
            <v-btn @click="
            $emit('closeDialog');
            model = 0;
            " icon="mdi-close" color="primary" outline variant="tonal" size="small"></v-btn>
          </template>
        </v-card-item>
      </v-sheet>
      <v-divider></v-divider>
      <v-card-text class="pb-12 text-news pa-2">
        <v-container style="display: flex; justify-content: center; " class="pa-0">
          <v-row class="dialog-container" style="max-width: 550px;">
            <v-col cols="12">
              <h2 class="my-6" style="font-weight: 700;">{{ noticiaSeleccionada?.title }}</h2>
              <v-carousel v-if="noticiaSeleccionada?.galery" hide-delimiter-background hide-delimiters v-model="model"
                style="border-radius: 10px;" class="mb-5" height="300px">
                <v-carousel-item v-for="galleryItem in noticiaSeleccionada?.galery" :key="galleryItem" :src="galleryItem"
                  cover />
              </v-carousel>

              <NuxtImg v-else :src="noticiaSeleccionada?.cover.original" class="cover" width="100%"
                :alt="noticiaSeleccionada?.title" />
              <div class="galery-modal">
                <NuxtImg v-for="(i, index) in noticiaSeleccionada.galery" :key="index" preload :src="i" quality="10"
                  :draggable="true" width="100%" class="cursor-pointer" :alt="noticiaSeleccionada?.title"
                  @click="getModelValue(index)" />
              </div>

              <!-- </v-col>
          <v-col cols="12" md="6"> -->


              <div class="text-news mt-6 " style="font-weight: 300;" v-html="noticiaSeleccionada?.context" id="context">
              </div>
            </v-col>
            <v-col cols="12" v-if="noticiaSeleccionada.filesInfo?.length > 0 || noticiaSeleccionada.documentUrl">
              <h3 class="mt-6">Enlaces:</h3>
              <div class="mb-6">
                <v-btn class="px-12" color="primary" style="color: #fff; border-radius:10px"
                  v-if="noticiaSeleccionada.documentUrl" link :href="noticiaSeleccionada.documentUrl" target="_blank">{{
                    noticiaSeleccionada.titleDocumentUrl ? noticiaSeleccionada.titleDocumentUrl : 'Ver Enlace' }}</v-btn>
                <ul class="mt-6">
                  <li v-for="(files, index) in noticiaSeleccionada.filesInfo">
                    <a :class="{ 'link-blank-a-link': true, 'loading01': files.loadingView == true }"
                      @click="viewDoc(files)"><v-icon size="14px">mdi-file</v-icon> {{ files.title }}</a>
                  </li>
                </ul>
              </div>

            </v-col>
            <v-col cols="12">
              <v-card-actions>
                <v-btn prepend-icon="mdi-share" block :loading="shareLoading" @click="copyUrl(noticiaSeleccionada)">compartir</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

    </v-card>
    <v-snackbar
      v-model="snackbar"
      color="success"
      location="top"
    >
      {{ text }}

      <template v-slot:actions>
        <v-btn
          color="#fff"
        
          small
          icon="mdi-close"
          @click="snackbar = false"
        >
       
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import axios from "axios";
import { Noticia } from "@/types";
import { dateFormat } from "@/helpers";

interface Props {
  noticiaSeleccionada: Noticia;
}

defineProps<Props>();

const model = ref<number>(0);
const shareLoading = ref<boolean>(false);
const snackbar = ref<boolean>(false);
const text = ref<string>('');

const getModelValue = computed(() => {
  return (index: number) => {
    if (model.value !== index) model.value = index;
  };
});

const viewDoc = async (item: any) => {
  // console.log(item);
  item.loadingView = true;
  try {
    const { data, headers } = await axios.get(
      `https://api-documentos.enee.hn/api/files/download/${item.key}`,
      { responseType: "blob" }
    );

    const blob = new Blob([data], {
      type: headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);

    const newTab = window.open(url, "_blank");

    newTab?.addEventListener("unload", () => {
      URL.revokeObjectURL(url);
    });
    setTimeout(() => {
      item.loadingView = false;
    }, 1000);
  } catch (error: any) {
    item.loadingView = false;
    throw new Error(error.message);
  }
};

const copyUrl = async (item: any) => {
  shareLoading.value = true;
  try {
    await navigator.clipboard.writeText(`https://enee.hn/new/${item.id}`)
    // console.log('Texto copiado al portapapeles')
    text.value = 'Link de noticia copiado al portapapeles'
   snackbar.value = true;
 
  } catch (err) {

    console.error('Error al copiar al portapapeles:', err)
  }
  setTimeout(() => {
    shareLoading.value = false;
    snackbar.value = false;
  }, 3000);

}


</script>

<style scoped lang="scss">
@import "@/assets/scss/responsive";
// @import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700&display=swap');

.btn {
  background: #5fd1de;
  border-radius: 10px;
  color: #fff;
  width: 160px;
}

.dialog-image {
  @include responsive(desktop) {
    width: 500px;
  }

  height: 20rem;
  width: 100%;
}

.dialog-container {
  @include responsive(mobile) {
    max-width: 100% !important;
  }
}

.galery-modal {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  img {
    width: calc(25% - 6px);
    border-radius: 10px;
    margin: 3px;
    height: 100px;
    object-fit: cover;
  }
}

.title-card {
  width: 80%;
  overflow: hidden;
  background-color: #5fd1de;
}

.cover {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

ul {
  list-style: none;
}

.link-blank-a-link {
  color: #151515;
  margin-right: 10px;
  text-decoration: none;
  white-space: nowrap;
  font-size: 14px;
  transition: 0.2s ease;
  margin-top: 10px;
  cursor: pointer !important;

  &:hover {
    text-decoration: underline;
    color: #5fd1de;
  }

  &:after {
    background-image: url("/arrowblack.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: inline-block;
    height: 0.5em;
    margin-left: 0.4em;
    transition: 0.2s ease;
    vertical-align: middle;
    width: 0.5em;
  }
}


/* code #1 */
.loading01 {

  animation: loading01 1.4s infinite alternate;
}

@keyframes loading01 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
</style>
