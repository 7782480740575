import { ref } from "vue";

import { Noticia } from "@/types";

export const useModal = () => {
  const noticiaSeleccionada = ref<Noticia | null>();
  const dialog = ref<boolean>(false);

  const openModal = (noticia: Noticia) => {
    dialog.value = true;
    noticiaSeleccionada.value = noticia;
  };

  const closeModal = () => {
    dialog.value = false;
    noticiaSeleccionada.value = null;
  };

  return {
    dialog,
    noticiaSeleccionada,
    closeModal,
    openModal,
  };
};
