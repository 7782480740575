import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const format = (date: number) => {
  return moment(date)
    .tz("America/Tegucigalpa")
    .format("DD/MM/YYYY, hh:mm:ss A ");
};

export const dateFormat = (date: number, withSeconds: boolean = false) => {
  return withSeconds ? format(date * 1000) : format(date);
};
